/**=====================
      90. React-Plugin CSS Start
==========================**/
.simple-list {
  .list-group-item {
    padding: 0;
    border: none;
    background-color: transparent;
  }
}
body.dark-only .page-wrapper .list-group:not(.simple-list) .list-group-item,
.list-group-item {
  padding: 0;
  border: none;
  background-color: transparent;
}
img.media {
  display: unset;
  border: none;
  margin-bottom: 0;
}
ul.list-group {
  display: block;
  li.list-group-item.active {
    background-color: $transparent-color;
    border-color: $transparent-color;
  }
  img {
    width: auto;
    height: auto;
  }
  i {
    margin-right: unset;
  }
}
// slick
.slick-page {
  .slick-slider {
    margin: -8px 0 20px;
  }
}

.slick-slider {
  margin: -7px 0 0;

  .item {
    &:focus {
      outline: none;
    }
  }

  .slick-dots {
    li {
      button {
        &:before {
          font-size: 12px;
          color: var(--theme-default);
        }
      }

      &.slick-active {
        button {
          &:before {
            color: var(--theme-default);
          }
        }
      }
    }
  }
}

// range slider
.rangeslider-horizontal,
.rangeslider-vertical {
  .rangeslider__fill {
    background-color: var(--theme-default) !important;
  }

  .rangeslider__handle {
    &:focus {
      outline: none;
    }
  }
}

.slider-vertical,
.vertical-height {
  .value {
    text-align: center;
  }
}

.rangeslider-vertical {
  .rangeslider__labels {
    margin: 0 0 0 32px !important;
  }
}

.input-range__slider {
  background: var(--theme-default);
  border: 1px solid var(--theme-default);
}

.input-range__track--active {
  background: var(--theme-default);
}

.input-range {
  margin-bottom: 34px;
}

// dropzone
.dzu-dropzone {
  border: 2px dashed var(--theme-default) !important;
  background: rgba($primary-color, 0.2);
  overflow: auto !important;

  .dz-message {
    .note {
      background-color: $transparent-color;
    }
  }
}

.dzu-inputLabel {
  color: var(--theme-default) !important;
  text-align: center;
  margin-bottom: 0;
}

.dzu-previewContainer {
  padding: 30px !important;
  min-height: 115px;
}

.dzu-inputLabelWithFiles {
  margin-left: 30px !important;
  background-color: var(--theme-default) !important;
  color: $white !important;
}

.dzu-submitButton {
  background-color: var(--theme-default) !important;

  &:disabled {
    background-color: #e6e6e6 !important;
  }
}

// Image Cropper
.input-cropper {
  margin-bottom: 15px;
  border: 1px solid #ced4da;
  padding: 10px;
  border-radius: 4px;
  width: auto;

  input {
    width: -webkit-fill-available;
  }
}

.crop-portion {
  margin-left: 0;
  margin-top: 30px;
  vertical-align: baseline;
}

.cropper-view-box {
  outline-color: var(--theme-default);
  outline: 1px solid var(--theme-default);
}

.cropper-point {
  background-color: var(--theme-default);
}

// sticky
.note {
  background-color: rgba($primary-color, 0.3);

  &:nth-child(2n) {
    background: rgba($secondary-color, 0.3);
  }
}

.react-stickies-wrapper {
  .close {
    &:before,
    &:after {
      background: $transparent-color !important;
    }
  }

  .react-grid-layout {
    .react-grid-item {
      &:nth-child(1n) {
        .note {
          background: lighten($primary-color, 35%) !important;
        }
      }

      &:nth-child(2n) {
        .note {
          background: lighten($secondary-color, 35%) !important;
        }
      }

      &:nth-child(3n) {
        .note {
          background: lighten($success-color, 50%) !important;
        }
      }

      &:nth-child(4n) {
        .note {
          background: lighten($info-color, 30%) !important;
        }
      }

      &:nth-child(5n) {
        .note {
          background: lighten($warning-color, 35%) !important;
        }
      }

      &:nth-child(6n) {
        .note {
          background: lighten($danger-color, 25%) !important;
        }
      }
    }
  }
}

// sweetalert
.lead {
  margin-bottom: 5px;
}

.swal2-container {
  .swal2-actions {
    .swal2-styled {
      font-size: 14px;
    }
  }
}

.swal2-styled {
  &.swal2-confirm {
    background-color: var(--theme-default) !important;

    &:focus {
      box-shadow: 0 0 0 1px $white, 0 0 0 3px rgba($primary-color, 0.4);
    }
  }
}

.swal2-title {
  font-size: 20px !important;
}

.swal2-progress-steps {
  .swal2-progress-step,
  .swal2-progress-step-line {
    background: var(--theme-default) !important;
  }

  .swal2-progress-step {
    &.swal2-active-progress-step {
      background: var(--theme-default) !important;

      ~ .swal2-progress-step,
      ~ .swal2-progress-step-line {
        background: rgba($primary-color, 0.2) !important;
      }
    }
  }
}

// datetime picker
.bootstrap-datetimepicker-widget {
  tr {
    th,
    td {
      &:active,
      &.active {
        background-color: var(--theme-default);
      }

      span {
        &:active,
        &.active {
          background-color: var(--theme-default);
        }
      }
    }
  }
}

// upload
.fileContainer {
  padding: 0 !important;
  margin: 0 !important;
  box-shadow: none !important;

  .files-gallery {
    display: flex;
    justify-content: center;

    .files-gallery-item {
      width: 45%;
      display: flex;
      margin: 0 auto;
    }
  }

  .uploadPictureContainer {
    margin: 1% !important;
    padding: 10px;
  }

  .chooseFileButton {
    margin-top: 0 !important;
    padding: 6px 23px;
    background: $secondary-color;
    border-radius: 30px;
    color: $white;
    font-weight: 300;
    font-size: 14px;
    margin: 10px 0;
    transition: all 0.2s ease-in;
    cursor: pointer;
    outline: none;
    border: none;
  }
}

// daterange picker
.daterangepicker {
  td,
  th {
    &.available {
      &:hover {
        background-color: var(--theme-default);
      }
    }
  }
}

// to-do
.todo {
  .action-box {
    &.large {
      .icon {
        .icon-trash,
        .icon-check {
          color: var(--theme-default);
        }
      }
    }
  }

  .todo-list-wrapper {
    #todo-list {
      li {
        &.completed {
          .task-container {
            .task-label {
              color: var(--theme-default);
            }
          }
        }
      }
    }
  }

  @-webkit-keyframes taskHighlighter {
    0% {
      background: rgba($primary-color, 0.5);
    }

    100% {
      background: $white;
    }
  }

  @keyframes taskHighlighter {
    0% {
      background: rgba($primary-color, 0.5);
    }

    100% {
      background: $white;
    }
  }
}

// clock
.react-clock__face {
  border: 1px solid $white !important;
}

.react-clock__mark__body,
.react-clock__hand__body {
  background-color: $white !important;
}

// scrollable page
.scrollarea {
  max-width: 800px;
  max-height: 300px;

  .scrollbar-container {
    z-index: 7 !important;
  }
}

//form wizard
ol {
  &.progtrckr {
    list-style-type: none;
    padding: 0;
    display: flex;

    li {
      display: inline-block;
      text-align: center;
      line-height: 4.5rem;
      cursor: pointer;
      width: 100%;

      span {
        padding: 0 1.5rem;
      }

      &.progtrckr-doing {
        color: black;
        border-bottom: 4px solid #cccccc;

        &:before {
          content: "\2022";
          color: white;
          background-color: #cccccc;
          width: 1.2em;
          line-height: 1.2em;
          border-radius: 1.2em;
        }

        &:hover {
          &:before {
            color: #ff4500;
          }
        }
      }

      &.progtrckr-done {
        color: black;
        border-bottom: 4px solid #5cb85c;

        &:before {
          content: "\2713";
          color: white;
          background-color: #5cb85c;
          width: 1.2em;
          line-height: 1.2em;
          border-radius: 1.2em;
        }

        &:hover {
          &:before {
            color: #333;
          }
        }
      }

      &:after {
        content: "\00a0\00a0";
      }

      &:before {
        position: relative;
        bottom: -3.7rem;
        float: left;
        left: 50%;
      }

      &.progtrckr-todo {
        &:before {
          content: "\039F";
          color: silver;
          background-color: $transparent-color;
          width: 1.2em;
          line-height: 1.4em;
        }

        &:hover {
          &:before {
            color: #ff4500;
          }
        }
      }
    }
  }
}

// calender
.rbc-calendar {
  height: 1000px !important;
}

#external-events {
  position: absolute;
  z-index: 2;
  padding: 0 10px;
  border: 1px solid #ccc;
  background: #eee;
  overflow: auto;

  p {
    strong {
      font-size: 16px;
    }
  }
}

#external-events .fc-event {
  margin: 1em 0;
  cursor: move;
}

//   base-typeahead
.rbt-token {
  color: var(--theme-default) !important;
  background-color: rgba($primary-color, 0.1) !important;

  .rbt-token-remove-button {
    top: 1px !important;
  }
}
.rbt-aux {
  .rbt-close {
    margin-top: 0;
  }
}

// time-picker
.react_times_button {
  .wrapper {
    display: block;
  }
}

.basic-calendar {
  .demo-app-calendar {
    .fc-daygrid-day-events {
      .fc-event {
        background-color: #7366ff;
        border: 1px solid #7366ff;
        color: white;
        font-size: 11.9px;
        padding: 0 10px;
        .fc-daygrid-event-dot {
          display: none;
        }
        .fc-event-time {
          display: none;
        }
      }
    }
  }

  .fc-scrollgrid {
    a {
      color: var(--body-font-color);
    }
  }
  .calendar-basic #external-events {
    background-color: $white;
  }
}

.time_picker_wrapper2 {
  display: flex;

  .time_picker_container {
    width: 44%;
  }

  .gap {
    padding: 0 5px;
  }
}

// toastr
.Toastify__close-button {
  font-weight: 500;

  svg {
    stroke: $white !important;
    fill: $white !important;
  }
}

.Toastify__toast--success {
  background: lighten($success-color, 15%) !important;
  color: $white !important;
  font-weight: 500;

  svg {
    fill: transparent !important;
    stroke: $white !important;
  }

  .Toastify__progress-bar {
    background: lighten($success-color, 35%) !important;
  }
}

.Toastify__toast--info {
  background: lighten($info-color, 15%) !important;
  color: $white !important;

  svg {
    fill: transparent !important;
    stroke: $white !important;
  }

  .Toastify__progress-bar {
    background: lighten($info-color, 35%) !important;
  }
}

.Toastify__toast--warning {
  background: lighten($warning-color, 5%) !important;
  color: $white !important;

  svg {
    fill: transparent !important;
    stroke: $white !important;
  }

  .Toastify__progress-bar {
    background: lighten($warning-color, 15%) !important;
  }
}

.Toastify__toast--error {
  background: lighten($danger-color, 10%) !important;
  color: $white !important;
  font-weight: 500;

  svg {
    fill: transparent !important;
    stroke: $white !important;
  }

  .Toastify__progress-bar {
    background: lighten($danger-color, 20%) !important;
  }
}

// draggable card
.draggable-card {
  > div {
    > div {
      width: 100%;
      display: flex;

      .react-kanban-column {
        width: 100%;
      }

      .react-kanban-card {
        min-width: 100%;
        max-width: 100%;
      }
    }
  }
}

.ui-sortable {
  .react-kanban-board {
    div[data-rbd-droppable-id="board-droppable"] {
      width: 100%;
      display: flex;
    }

    .react-kanban-column {
      width: 100%;
    }

    .react-kanban-card {
      max-width: 100%;
    }
  }
}

.jkanban-container {
  .custom-board {
    .kanban-drag {
      .react-kanban-column {
        &:nth-child(2) {
          div[data-rbd-drag-handle-context-id="1"] {
            background-color: $warning-color;

            ~ div {
              background-color: rgba($warning-color, 0.3);
            }
          }
        }

        &:nth-child(3) {
          div[data-rbd-drag-handle-context-id="1"] {
            background-color: $success-color;

            ~ div {
              background-color: rgba($success-color, 0.3);
            }
          }
        }
      }

      div[data-rbd-drag-handle-context-id="1"] {
        background-color: $info-color;
        color: $white;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        ~ div {
          background-color: rgba($info-color, 0.3);
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
        }

        span {
          font-weight: 500;
        }

        span.badge {
          font-weight: 400;
        }
      }
    }
  }

  .kanban-container {
    display: flex;

    .kanban-board {
      width: 100%;
    }

    .kanban-item {
      .customers {
        ul.simple-list {
          li {
            background-color: $transparent-color;

            + li {
              img {
                margin: 0;
              }
            }
          }
        }
      }

      &:focus {
        outline: none;
      }

      .kanban-box {
        border: 1px solid $light-semi-gray;
        background-color: $white !important;
        color: $theme-body-font-color;

        &:focus {
          outline: none;
        }
      }
    }

    .kanban-drag {
      width: 100%;

      .react-kanban-column-header {
        font-weight: 500;

        &:focus {
          outline: none;
        }

        > div {
          &:focus {
            outline: none;
          }
        }
      }

      > div {
        padding: 0;

        > div {
          display: flex;
          width: 100%;
          margin-bottom: -25px;

          .react-kanban-column {
            width: 98%;
            background-color: $body-color;
            border-radius: 8px;
            padding: 0;
            margin-bottom: 30px;

            > div {
              width: 100%;
              padding: 20px;
              font-size: 16px;
              &[data-rbd-drag-handle-context-id="0"],
              &[data-rbd-drag-handle-context-id="2"] {
                border-bottom: 1px solid $light-semi-gray;
              }
              p {
                font-weight: 400;
              }

              > div {
                padding-bottom: 0;
              }
            }

            .react-kanban-board {
              > div {
                width: 100%;
              }

              + .react-kanban-board {
                margin-top: 20px;
              }
            }
          }
        }
      }
    }
  }

  .react-kanban-column {
    background-color: $body-color;

    > div {
      > div {
        > div {
          display: block !important;
        }
      }
    }

    div[tabindex="0"] {
      + div[tabindex="0"] {
        margin-top: 20px;
      }
    }
  }
}

//DragNDrop
.ui-sortable {
  .container {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: var(--theme-default);

    div {
      padding: 10px;
      background-color: darken($primary-color, 1%);
      transition: opacity 0.4s ease-in-out;
      color: $white;
    }

    div {
      cursor: move;
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.ex-moved {
      background-color: #e74c3c;
    }

    &.ex-over {
      background-color: var(--theme-default);
    }

    &:nth-child(odd) {
      background-color: var(--theme-default);
    }
  }
}

.gh-fork {
  position: fixed;
  top: 0;
  right: 0;
  border: 0;
}

.gu-mirror {
  padding: 10px;
  background-color: darken($primary-color, 5%);
  transition: opacity 0.4s ease-in-out;
  color: $white;
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}

.gu-hide {
  display: none !important;
}

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.wrapper {
  display: table;
}

.gu-mirror {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.handle {
  padding: 0 5px;
  margin-right: 5px;
  background-color: darken($primary-color, 15%);
  cursor: move;
}

// timeline
.vertical-timeline {
  &::before {
    background: $light-color;
  }

  .vertical-timeline-element {
    p {
      font-size: 13px !important;
    }

    .vertical-timeline-element-icon {
      color: $white;
    }

    &:first-child,
    &:nth-child(7) {
      .vertical-timeline-element-icon {
        background-color: var(--theme-default);
      }
    }

    &:nth-child(2) {
      .vertical-timeline-element-icon {
        background-color: $secondary-color;
      }
    }

    &:nth-child(3),
    &:last-child {
      .vertical-timeline-element-icon {
        background-color: $success-color;
      }
    }

    &:nth-child(4) {
      .vertical-timeline-element-icon {
        background-color: $info-color;
      }
    }

    &:nth-child(5) {
      .vertical-timeline-element-icon {
        background-color: $warning-color;
      }
    }

    &:nth-child(6) {
      .vertical-timeline-element-icon {
        background-color: $danger-color;
      }
    }
  }
}

.vertical-timeline-element-content {
  box-shadow: 1px 1px 2px 1px rgba($black, 0.08);
  background-color: $light;

  audio {
    width: 100%;
    margin-top: 15px;
  }

  .vertical-timeline-element-date {
    font-size: 14px !important;
  }

  .vertical-timeline-element-content-arrow {
    border-right: 7px solid $light-color;
  }
}

.vertical-timeline--two-columns {
  .vertical-timeline-element-content {
    .vertical-timeline-element-date {
      top: 10px !important;
    }
  }
}

.vertical-timeline--one-column {
  &:before {
    left: 28px !important;
  }

  .vertical-timeline-element-icon {
    width: 60px;
    height: 60px;
  }

  .vertical-timeline-element-content {
    margin-left: 90px;

    img {
      width: 500px;
    }

    .vertical-timeline-element-date {
      float: none;
      display: block;
    }
  }
}

.rdt_Table {
  .rdt_TableHead {
    .rdt_TableHeadRow {
      .rdt_TableCol {
        &:nth-child(4) {
          .rdt_TableCol_Sortable {
            justify-content: center;

            span {
              display: none;
            }
          }
        }

        .rdt_TableCol_Sortable {
          justify-content: flex-start;

          > div {
            font-weight: 500 !important;
          }
        }
      }
    }
  }

  .rdt_TableBody {
    .rdt_TableRow {
      .rdt_TableCell {
        padding: 12px;

        &:nth-child(4) {
          justify-content: center !important;
        }

        + .rdt_TableCell {
          justify-content: flex-start;
        }
      }
    }
  }
}

// leafleft map
.leaflet-top,
.leaflet-bottom {
  z-index: 7 !important;
}

.leaflet-pane {
  z-index: 1 !important;
}

// vector map
.map-block {
  svg {
    g {
      transform-origin: 0 0;
    }
  }
}

#gmap-simple {
  svg {
    path {
      fill: var(--theme-default) !important;
    }
  }
}

#usa {
  svg {
    path {
      fill: $secondary-color !important;
    }
  }
}

#canada {
  svg {
    path {
      fill: $success-color !important;
    }
  }
}

#asia {
  svg {
    path {
      fill: $warning-color !important;
    }
  }
}

#europe {
  svg {
    path {
      fill: $danger-color !important;
    }
  }
}

#america {
  svg {
    path {
      fill: $dark-color !important;
    }
  }
}
.ace_editor {
  width: auto !important;
  overflow: auto;
}
.carousal {
  .slick-slider {
    .slick-slide {
      margin-right: 15px;
    }
  }
}
.jvectormap-zoomin,
.jvectormap-zoomout {
  width: 20px;
  height: 20px;
}

.jvectormap-zoomin {
  bottom: 65px;
}

.jvectormap-zoomout {
  bottom: 30px;
}

.jvectormap-zoomin,
.jvectormap-zoomout,
.jvectormap-goback {
  position: absolute;
  border-radius: 3px;
  background: #292929;
  padding: 5px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  box-sizing: content-box;
}

// rating
.rating {
  align-items: center;

  span {
    .fa {
      color: var(--theme-default);
    }

    span {
      span {
        color: var(--theme-default);
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .jkanban-container {
    .kanban-container {
      .kanban-drag {
        padding: 0;

        > div {
          width: 100% !important;

          > div {
            div[data-rbd-drag-handle-context-id="1"] {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}
.page-wrapper .page-header .header-wrapper {
  .bookmark-flip {
    width: unset !important;
    box-shadow: unset !important;
    background-color: transparent !important;
    overflow: initial;
    .Typeahead-menu {
      background-color: transparent !important;
      box-shadow: none;
      left: -1px;
      max-height: 85px;
      overflow: auto;
      top: 75px;
      .ProfileCard-realName {
        width: 100%;
      }
      .ProfileCard-details {
        float: unset;
        text-align: left;
      }

      .ProfileCard {
        border: none !important;
        padding: 8px;
        position: relative;
        margin: 0;
        &:hover {
          background-color: var(--chart-border);
        }
        .realname {
          text-transform: capitalize !important;
          text-decoration: none;
        }
        .pull-right {
          margin-left: auto;
        }
      }
    }
  }
  .flip-card {
    width: 220px;
    height: 245px;
    background-color: transparent;
    .flip-card-inner {
      position: relative;
      height: 100%;
      text-align: center;
      transition: transform 0.6s;
      transform-style: preserve-3d;
      .back {
        position: absolute;
        backface-visibility: hidden;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
        width: 220px;
        height: auto;
        button {
          background-color: $transparent-color;
          color: $primary-color;
          border: none;
          &:focus {
            outline: $transparent-color;
          }
        }
      }

      .back {
        background-color: $white;
        transform: rotateY(180deg);
        li {
          &:first-child {
            height: 160px !important;
            margin-top: 0 !important;
          }
          &:last-child {
            padding: 20px 0;
            border-top: 1px solid var(--chart-border);
          }
        }
        button {
          width: 100%;
        }
        .flip-back-content {
          .Typeahead-menu {
            max-height: 85px;
          }
          input {
            border-radius: 8px;
            padding: 6px 12px;
            width: 100%;
            border: 1px solid var(--chart-border);
            &:focus {
              outline: unset;
            }
          }
        }
      }
      &.flipped {
        transform: rotateY(180deg);
        .front {
          opacity: 0;
        }
      }
    }
  }
}

.default-datepicker {
  .rdp {
    margin: 0;
    &-caption_label {
      padding: 0;
    }
    &-nav {
      &_button {
        padding: 0;
        background: transparent !important;
        width: unset;
        height: unset;
        svg {
          width: 11px;
          height: 11px;
        }
      }
    }
    &-caption_label {
      color: #2b2b2b;
      font-size: 20px;
      font-weight: 500;
    }
    &-head_cell {
      span {
        color: #2b2b2b;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}

/**=====================
      90. React-Plugin CSS Ends
==========================**/

.product-table {
  &.table-bordernone {
    .rdt_Table {
      border: 1px solid var(--chart-border);
      .rdt_TableHead {
        .rdt_TableHeadRow {
          border: none;
          .rdt_TableCol {
            border: none;
          }
        }
      }
      .rdt_TableBody {
        .rdt_TableRow {
          border: none;
        }
      }
    }
  }
  &.orderHistoryTable {
    .order-process {
      color: $theme-font-color;
      font-weight: 400;
    }
    .rdt_TableBody {
      .rdt_TableCell {
        div {
          color: var(--body-font-color);
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }
  .gKbhqU {
    border: none;
  }
  .rdt_Table {
    border: 1px solid var(--chart-border);
  }
  .rdt_TableHead {
    .rdt_TableHeadRow {
      .rdt_TableCol {
        border: none;
        h6 {
          margin: 0;
        }
      }
    }
  }

  .rdt_TableHeadRow .rdt_TableCol {
    vertical-align: middle;
    border-top: none;
    border: 1px solid var(--chart-border);
    font-size: 16px;
    font-weight: 600;
  }
  .rdt_TableHeadRow .rdt_TableCol,
  .rdt_TableBody .rdt_TableRow .rdt_TableCell {
    padding: 0.75rem;
  }

  .rdt_TableBody {
    .rdt_TableRow {
      &:nth-child(odd) {
        background-color: rgb(246 246 246);
        border-color: var(--chart-border);
      }
      &:nth-child(even) {
        background-color: #fff;
        border-color: var(--chart-border);
      }
      &:hover {
        background-color: rgb(246 246 246);
      }
      .rdt_TableCell {
        vertical-align: middle;
        color: $light-text;
        font-weight: 600;
        .product-name {
          a {
            font-size: 15px;
            color: $dark-gray;
            line-height: 25px;
          }
          .order-process {
            font-size: 13px;
            padding-left: 15px;

            .order-process-circle {
              position: relative;
              &:before {
                position: absolute;
                content: "";
                width: 4px;
                height: 4px;
                border-radius: 100%;
                background-color: $dark-gray;
                left: -15px;
                top: 6px;
              }

              &.shipped-order {
                &:before {
                  background-color: $success-color;
                }
              }

              &.cancel-order {
                &:before {
                  background-color: $danger-color;
                }
              }
            }
          }
        }
        &.total-amount {
          color: $theme-body-font-color;
        }
      }
    }
  }

  .product-name {
    h6 {
      color: $theme-body-font-color;
      margin-bottom: unset;
      font-size: 14px;
    }
    span {
      color: $theme-body-font-color;
    }
  }
}

.email-compose {
  .cke_chrome {
    border: none;
  }
  .cke_top {
    border: none;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
  }
  .cke_bottom {
    display: none;
  }
}

.theme-text {
  color: var(--body-font-color);
}

.focuse-none {
  &:focus {
    border: none;
    box-shadow: none;
    outline: unset;
  }
}

.input-group-text-none {
  .input-group-text {
    display: none;
  }
}

.img-40 {
  width: 40px;
  height: 40px;
}
.deni-react-treeview-container.classic {
  width: 100%;
  .deni-react-treeview-item-container.classic {
    .treeview-item-example {
      display: flex;
    }
    .actionButton {
      margin-left: 6px;
    }
  }
}
body.dark-only {
  .gYaFyO {
    border-bottom: var(--chart-border);
    &:hover {
      border-color: var(--chart-border);
    }
  }
  .qty-box {
    .input-group-prepend {
      padding: 0;
    }
  }
}

.datetime-picker {
  .input-group {
    flex-wrap: unset;
  }
  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 96px;
  }
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: $primary-color;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: $primary-color;
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: $primary-color;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  background-color: rgba($primary-color, 0.2);
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: $primary-color;
}
.react-datepicker__close-icon {
  &::after {
    background-color: $primary-color;
  }
}
.date-range-picker {
  .daterange-card {
    padding-bottom: 10px;
  }
  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 96px;
  }
}
.date-picker .cal-date-widget {
  .react-datepicker {
    border: none;
    padding: 8px 16px 0;
    width: 100%;
  }
  .react-datepicker__navigation {
    top: 20px;
  }
  .react-datepicker__day--keyboard-selected {
    background-color: $primary-color;
  }
  .react-datepicker__navigation-icon {
    &::before {
      border-width: 2px 2px 0 0;
      width: 8px;
      height: 8px;
    }
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    height: 32px;
  }
  .react-datepicker__day--outside-month {
    color: $gray-60;
  }
  .react-datepicker__day,
  .react-datepicker__month-text,
  .react-datepicker__quarter-text,
  .react-datepicker__year-text {
    &:hover {
      background-color: $primary-color;
      color: $white;
    }
  }
}
.react-datepicker {
  border: 1px solid $light-semi-gray;
}
.react-datepicker__header {
  background-color: $white;
  border: none;
}
.react-datepicker__navigation {
  background-color: $primary-color;
  border-radius: 20px;
  color: $white;
  top: 13px;
  &.react-datepicker__navigation--previous {
    left: 4px;
  }
  &.react-datepicker__navigation--next {
    right: 4px;
  }
}
.react-datepicker__day--selected {
  background-color: $primary-color;
  border-radius: 10px;
  &:hover {
    background-color: $primary-color;
  }
}
.react-datepicker__navigation-icon--next {
  left: -3px;
}
.react-datepicker__navigation-icon {
  top: 0px;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: $primary-color;
  padding: 10px 10px 20px;
}
.react-datepicker__navigation-icon--previous {
  right: 0;
}
.react-datepicker__day-name {
  font-weight: bold;
}
.react-datepicker__week,
.react-datepicker__day {
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-datepicker__navigation-icon--next,
.react-datepicker__navigation-icon--previous {
  &::before {
    top: 10px;
    left: -3px;
    border-color: $white;
  }
}
.react-datepicker-popper[data-placement^="top"],
.react-datepicker-popper[data-placement^="bottom"] {
  .react-datepicker__triangle {
    margin-left: -10px;
  }
}
.support-table {
  .rdt_Table {
    .rdt_TableHead {
      .rdt_TableHeadRow {
        .rdt_TableCol:nth-child(4) {
          .rdt_TableCol_Sortable {
            justify-content: start;
          }
        }
      }
    }
    .rdt_TableBody {
      .rdt_TableRow {
        border-color: var(--chart-border);
        .rdt_TableCell:nth-child(4) {
          justify-content: start !important;
        }
      }
    }
  }
}

.loader-wrapper.loderhide {
  display: none;
}
